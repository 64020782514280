import {
    createRouter,
    createWebHistory
} from 'vue-router'
import routes from "./routes";
import store from "../store/index";

const router = createRouter({ 
    history: createWebHistory(),
    routes
});


router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ?
            to.meta.middleware : [to.meta.middleware];

        const context = {
            to,
            from,
            next,
            store,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({
            ...context,
            next: nextMiddleware
        });
    }

    return next();
});

const DEFAULT_TITLE = 'Uproster';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    document.title = `${DEFAULT_TITLE} | ${to.meta.title}` || DEFAULT_TITLE;
});


//Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index, 1);
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        });
    };
}

export default router