const state = {
    loadingStatus: false,
    loginLoadingStatus: false,
};

const mutations = {
    loadingStatus(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    },
    loginLoadingStatus(state, newLoginLoadingStatus) {
        state.loginLoadingStatus = newLoginLoadingStatus

        //console.log(newLoginLoadingStatus,state);
    },
};

const actions = {

};

const getters = {
    loadingStatus(state) {
        return state.loadingStatus;
    },
    loginLoadingStatus(state) {
        return state.loginLoadingStatus;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};