import AuthService from '../../services/auth.service';
import UserService from "../../services/user.service";

const user = JSON.parse(localStorage.getItem('user'));

const state = user ?
    {
        status: {
            loggedIn: true,
        },
        user,
        permissions: null,
    } :
    {
        status: {
            loggedIn: false,
        },
        user,
        permissions: null
    };

const getters = {
    isLoggedIn: state => state.status.loggedIn,
    user: state => state.user,
    permissions: state => state.permissions,
};


const actions = {
    login({
        commit
    }, user) {
        return AuthService.login(user).then(
            user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({
        commit
    }) {
        AuthService.logout();
        commit('logout');
    },

    getUserDetails({
        commit
    }) {
        return UserService.getUser(user).then(
            user => {
                //console.log('in user details',user);
                if(user.is_active == 1)
                {
                    commit('updateUserDetails', user);
                    return Promise.resolve(user);
                }else{
                    console.log('failure');
                    commit('loginFailure');
                }
                
            },
            error => {
                console.log('reject,',error);
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },

    getLeemoUserDetails({
        commit
    },user) {
        return UserService.ssoLogin(user).then(
            user => {
                //console.log('user 1',user);
                commit('updateUserDetails', user);
                return Promise.resolve(user);
            },
            error => {
                //console.log('error',error);
                return Promise.reject(error);
            }
        );
    },

    ssoLogin({
        commit
    }, user) {  
        commit('loginSuccess', user);
        commit('updateUserDetails', user);
        return Promise.resolve(user);
       
    }
};

const mutations = {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.user = user.userDetails;
        state.permissions = user.permissionData;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
        state.permissions = null;
        state.isActive = false;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
        state.permissions = null;
    },
    updateUserDetails(state, user) {
        state.permissions = user.permissionData;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};