import axios from 'axios';
//import userService from './user.service';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  async login(user) {
    const response = await axios
      .post(`${API_URL}login`, {
        email: user.email,
        password: user.password
      });
    const token = response.data.userDetails.authToken;
    if (token) {
      localStorage.setItem('user', JSON.stringify(response.data.userDetails));
    }
    return response.data;
  }

  logout() {
    //userService.ssoLogout();
    //console.log('logout');

    
    //userService.ssoLogout();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }
}

export default new AuthService();