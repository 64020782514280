<template>
  <div class="loader-wrapper" v-if="loadingStatus">
    <img
      src="@/assets/images/LoaderGif.gif"
      class="loader-gif"
      alt="loader"
      v-if="true"
    />
  </div>
</template>
<script>
export default {
  computed: {
    loadingStatus: function () {
      return this.$store.getters.loadingStatus;
    },
  },
};
</script>
