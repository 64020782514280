<template>
  <div class="loader-wrap" v-if="loginLoadingStatus">
      <div class="loader-outer" >
          <img src="@/assets/images/uproster.svg" alt="">
          <div class="loading-bar"></div>
      </div>
    </div>
</template>
<script>
export default {
  computed: {
    loginLoadingStatus: function () {
      return this.$store.getters.loginLoadingStatus;
    },
  },
};
</script>
<style>
.loader-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(255 255 255);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-bar {
  width: 100px;
  height: 2px;
  background-color: #d6cec2;
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  margin: 25px auto 0;
}
    
    .loader-outer img {
      max-width: 200px;
    }
    
    .loading-bar {
      width: 100px;
      height: 2px;
      background-color: #d6cec2;
      border-radius: 10px;
      margin-top: 25px;
      overflow: hidden;
      position: relative;
    }
    
    .loading-bar::after {
      content: '';
      width: 50px;
      height: 2px;
      position: absolute;
      background-color: #052322;
      transform: translateX(-20px);
      animation: loop 2s ease infinite;
    }
    
    @keyframes loop {
    
      0%,
      100% {
        transform: translateX(-28px);
      }
    
      50% {
        transform: translateX(78px)
      }
    }	
</style>