<template>
  <div class="wrapper" :class="{ 'main-page': isLoggedIn }">
    <!-- site header -->
    <AutoLoginLoader />
    <TopBar />
    <router-view />
    <BottomBar />
    <loader />
  </div>
</template>
<style lang="css">
@import "~linearicons/dist/web-font/style.css?ver=0.1.0";
@import "assets/css/profile.css?ver=0.1.0";
@import "assets/css/custom-modal.css?ver=0.1.0";
@import "assets/css/login.css?ver=0.1.0";
@import "assets/css/side-modal.css?ver=0.1.0";
@import "assets/css/financial-graphs.css?ver=0.1.0";
</style>
<script>
import TopBar from "./components/Header";
import BottomBar from "./components/Footer";
import Loader from "./components/Loader.vue";
import AutoLoginLoader from "./components/AutoLoginLoader.vue";

export default {
  name: "App",
  components: {
    TopBar,
    BottomBar,
    Loader,
    AutoLoginLoader,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  beforeCreate() {
    this.$store.commit("loadingStatus", false);
    //this.$store.commit("loginLoadingStatus", false);
  },
  watch: {
    async $route() {
      if (this.isLoggedIn) await this.$store.dispatch("getUserDetails"); 
    },
  },
};
</script>
